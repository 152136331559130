import Custom from '@/modules/incentive-grant-service/store/custom'

const stateVars = {
  commonObj: {
    hasDropdownLoaded: false,
    perPage: 10,
    dateFormat: 'dd/mm/YYYY',
    timeFormat: 'h:m',
    loading: false,
    listReload: false,
    customListReload: false,
    enlistedUniversityList: [],
    educationLevelList: [],
    grantList: [],
    itemList: [],
    millTypeList: [],
    applicationList: [],
    seasonSetupList: [],
    millInfoList: [],
    subsidyTypeList: [],
    subsidyList: [],
    cultivationMethodList: [],
    projectList: [],
    regionInfoList: [],
    villageList: [],
    agMaterialTypeList: [],
    agMaterialList: [],
    circularInfoList: [],
    barcMsApplicationNameList: [],
    cropList: [],
    publicationTypeList: [],
    committeeList: [],
    committeeRollList: [],
    supplierList: [],
    blockList: [],
    agMaterialUnitList: [],
    allocationList: [],
    subzoneOfficeList: [],
    centerList: [],
    unitList: [],
    instrumentList: [],
    subsidyStatusList: Custom.subsidyStatusList,
    subsidyCircularTypeList: Custom.subsidyCircularTypeList,
    stockTypeList: Custom.stockTypeList
  }
}

export default stateVars
