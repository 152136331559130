import RestApi, { incentiveGrantServiceBaseUrl } from '../config/api_config'

export default {
  computed: {
    hasDropdownLoadedIncentiveGrantService () {
      return this.$store.state.incentiveGrant.commonObj.hasDropdownLoaded
    }
  },
  watch: {
    hasDropdownLoadedIncentiveGrantService: function (newValue) {
      if (!newValue) {
        this.loadDropdownCommonConfig()
      }
    }
  },
  created () {
    const hasDropdownLoadedIncentiveGrant = this.$store.state.incentiveGrant.commonObj.hasDropdownLoaded
    if (!hasDropdownLoadedIncentiveGrant || window.performance) {
    }
    this.loadDropdownCommonConfig()
  },
  methods: {
    loadDropdownCommonConfig () {
      RestApi.getData(incentiveGrantServiceBaseUrl, 'common-dropdowns', null).then(response => {
        if (response.success) {
          this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', {
            hasDropdownLoaded: true,
            enlistedUniversityList: response.data.enlistedUniversityList,
            educationLevelList: response.data.educationLevelList,
            grantList: response.data.grantList,
            itemList: response.data.itemList,
            barcMsApplicationNameList: response.data.barcMsApplicationNameList,
            cultivationMethodList: response.data.cultivationMethodList,
            cropList: response.data.cropList,
            circularCropList: response.data.circularCropList,
            millInfoList: response.data.millInfoList,
            subsidyTypeList: response.data.subsidyTypeList,
            subsidyList: response.data.subsidyList,
            projectList: response.data.projectList,
            regionInfoList: response.data.regionInfoList,
            applicationList: response.data.applicationList,
            agMaterialTypeList: response.data.agMaterialTypeList,
            agMaterialList: response.data.agMaterialList,
            publicationTypeList: response.data.publicationTypeList,
            circularInfoList: response.data.circularInfoList,
            narseInstituteList: response.data.narseInstituteList,
            committeeList: response.data.committeeList,
            fundSubHeadList: response.data.fundSubHeadList,
            committeeRollList: response.data.committeeRollList,
            supplierList: response.data.supplierList,
            agMaterialUnitList: response.data.agMaterialUnitList,
            measurementUnitsList: response.data.measurementUnitsList,
            allocationList: response.data.allocationList,
            checkList: response.data.checkList,
            subzoneOfficeList: response.data.subzoneOfficeList,
            subZoneList: response.data.subZoneList,
            centerList: response.data.centerList,
            unitList: response.data.unitList,
            cultivationPolicy: response.data.cultivationPolicy,
            subProAprAllocationIdList: response.data.subProAprAllocationIdList,
            instrumentList: response.data.instrumentList,
            circularList: response.data.circularList,
            subsidyRateList: response.data.subsidyRateList,
            macMachineTypeList: response.data.macMachineTypeList,
            macBrandList: response.data.macBrandList
          })
          this.$store.dispatch('incentiveGrant/localizeIncentiveGrantDropdown', { value: this.$i18n.locale })
        }
      })
    }
  }
}
